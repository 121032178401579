/* Formateamos el label que servirá de contenedor */
.extras .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
   
  /* Ocultamos el checkbox html */
  .extras .switch input {
    display:none;
  }
   
  /* Formateamos la caja del interruptor sobre la cual se deslizará la perilla de control o slider */
  .extras .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
   
  /* Pintamos la perilla de control o slider usando el selector before */
  .extras .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
   
  /* Cambiamos el color de fondo cuando el checkbox esta activado */
  .extras input:checked + .slider {
    background-color: #005ED3;
  }
   
  /* Deslizamos el slider a la derecha cuando el checkbox esta activado */ 
  .extras input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
   
  /* Aplicamos efecto de bordes redondeados en slider y en el fondo del slider */
  .extras .slider.round {
    border-radius: 34px;
  }
   
  .extras .slider.round:before {
    border-radius: 50%;
  }

  /*PARA EL SWITCH DE LOS PAQUETES*/

  /* Formateamos el label que servirá de contenedor */
.package .switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
 
/* Ocultamos el checkbox html */
.package .switch input {
  display:none;
}
 
/* Formateamos la caja del interruptor sobre la cual se deslizará la perilla de control o slider */
.package .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}
 
/* Pintamos la perilla de control o slider usando el selector before */
.package .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
 
/* Cambiamos el color de fondo cuando el checkbox esta activado */
.package input:checked + .slider {
  background-color: #005ED3;
}
 
/* Deslizamos el slider a la derecha cuando el checkbox esta activado */ 
.package input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
 
/* Aplicamos efecto de bordes redondeados en slider y en el fondo del slider */
.package .slider.round {
  border-radius: 34px;
}
 
.package .slider.round:before {
  border-radius: 50%;
}